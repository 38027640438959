export default {
  "zipcode": "邮编",
  "Product": "产品",
  "sign up/log in": "注册/登录",
  "Log out": "登出",
  "Welcome to": "欢迎来到",
  "Email address or Phone Number": "邮箱或电话号码",
  "Please enter your email address or phone Number": "请输入您的邮箱或电话号码",
  "Enter your email address or phone Number": "输入您的邮箱或电话号码",
  "Change Password": "修改密码",
  "Password": "密码",
  "New Password": "新密码",
  "Please enter your password": "请输入您的密码",
  "Please enter your new password": "请输入您的新密码",
  "Enter your password": "输入您的密码",
  "Enter your new password": "输入您的新密码",
  "Hide": "隐藏",
  "Show": "显示",
  "Forget Password": "忘记密码",
  "Log In": "登录",
  "New to our store": "新客户到店",
  "Register Account": "注册账户",
  "Please enter your email address or phone Number. You will receive a verification code to create a new password.": "请输入您的邮箱或电话号码，您将收到登录验证码",
  "Send Code": "发送验证码",
  "A verification code has been sent to the email or phone. Please input to continue.": "验证码已发送到您邮箱或电话，请输入后进入下一步",
  "Verification Code": "验证码",
  "Please enter your verification code": "",
  "Enter your verification code": "请输入您的验证码",
  "Confirm": "确认",
  "Please enter a new password.": "请输入新密码",
  "New Password": "新密码",
  "Please enter your new password": "请输入您的新密码",
  "Enter your new password": "输入您的新密码",
  "Reenter Password": "确认密码",
  "Please enter your reenter password": "请再次输入密码",
  "Enter your reenter password": "再次输入密码",
  "Confirm reset": "确认重置",
  "Password successfully reset!": "密码重置成功",
  "You may now log in with your new password.": "您现在可以使用新密码登录",
  "Reset Password": "重置密码",
  "Close": "关闭",
  "Start business with us": "合作愉快",
  "By continuing, you agree to": "继续，即表示您同意",
  "Terms of Service": "服务条款",
  "and": "和",
  "Privacy Policy": "隐私政策",
  "Congratulations! Your sign-up was successful.": "恭喜，您注册成功",
  "Thank you for joining our community.": "感谢您加入我们的社区",
  "We have received your information, and our team will review and approve your request shortly.": "我们已收到您的信息，团队将尽快审核",
  "Please keep an eye on your email for further updates. We appreciate your patience and look forward to having you as a valued member. If you have any questions or need assistance, please don't hesitate to reach out to our support team.": "关于进一步信息的更新，请留意您的邮箱。 非常感谢您的耐心等待，期待您成为我们尊贵的会员。 如果您有任何疑问或需要协助的，请随时联系我们的支持团队",
  "Individual": "个人",
  "Retailer": "零售商",
  "Restaurant": "餐厅",
  "Manufacturer": "生产商",
  "Wholesaler / Distributor": "批发商 / 分销商",
  "Others": "其他",
  "Name": "姓名",
  "Please enter your name": "请输入您的姓名",
  "Enter your name": "输入您的姓名",
  "Email Address": "邮箱",
  "Please enter your email address": "请输入您的邮箱",
  "The enter is not valid Email Address": "输入的邮箱无效",
  "Enter your email address": "输入您的电邮箱",
  "Phone number": "电话号码",
  "Enter your phone number": "输入您的电话号码",
  "Company": "公司",
  "Please enter your company name": "请输入您的公司名称",
  "Enter your company name": "输入您的公司名称",
  "Company Phone": "公司电话",
  "Enter your company phone": "输入您的公司电话",
  "Business Type": "业务类型",
  "Enter your company business type": "输入您公司的业务类型",
  "EIN Number": "EIN 号码",
  "Enter your company EIN number": "输入您公司的 EIN 号码",
  "Address": "地址",
  "Please enter your address": "请输入您的地址",
  "Enter your address": "输入您的地址",
  "City": "城市",
  "Please enter your address city": "请输入您地址所在的城市",
  "Enter your address city": "输入您地址所在的城市",
  "State": "州",
  "Please enter your address state": "请输入您地址所在的州名",
  "Enter your address state": "输入您地址所在的州名",
  "Zip Code": "邮编",
  "Please enter your address zipcode": "请输入您地址所在的邮编",
  "Enter your address zipcode": "输入您地址所在的邮编",
  "OLONG RESTAURANT SUPPLIES": undefined,
  "TERMS OF SERVICE": "服务条款",
  "PRIVACY POLICY": "隐私政策",
  "GET IN TOUCH": "取得联系",
  "WORKING HOURS": "工作时间",
  "Monday - Saturday": "周一至周六",
  "8am - 5pm": "上午8点至下午5点",
  "Sunday": "周日",
  "9am - 3pm": "上午9点至下午3点",
  "CONTACT US": "联系我们",
  "Phone": "电话",
  "The enter is not valid Phone number": "输入的电话号码无效",
  "The enter is not valid Company Phone": "输入的公司电话无效",
  "My Profile": "我的账户",
  "My Favorites": "我的收藏",
  "My Cart": "购物车",
  "restaurant supplies": "餐饮供货商",
  "WE SPECIALIZE IN PROVIDING HUNDREDS OF HIGH-QUALITY GOODS SUCH AS SEAFOOD, VEGETABLES, MEAT, DRY GOODS, AND MORE": "专业级食材供货商：提供高品质的海鲜、蔬菜、肉类、干货等产品",
  "Product listing": "产品橱窗",
  "Discover Our Exceptional Product Collection: Elevate Your Lifestyle Today!": "高品质的食物，高品质的生活",
  "Filter": "筛选",
  "Clear filter": "清除",
  "All Product": "所有产品",
  "Search by Product Name": "按产品名称搜索",
  "Sort By": "排序方式",
  "Group": "组",
  "Category": "类别",
  "Product Name": "名称",
  "Price": "价格",
  "Item No": "产品编号",
  "Click to show more": "查看更多产品",
  "Add to cart": "加入购物车",
  "Cancel favorite successfully": "取消收藏成功",
  "Add favorite successfully": "收藏成功",
  "Description": "描述",
  "MORE PRODUCT": "更多产品",
  "Ref No": "参考号",
  "Product Description": "产品描述",
  "QTY": "数量",
  "Saved successfully": "保存成功",
  "My Profile Information": "我的账户信息",
  "My Address": "我的地址",
  "My Order": "我的订单",
  "My QR": "我的二维码",
  "My Subordinate": "我的团队",
  "Save": "保存",
  "Cancel": "取消",
  "Default Address": "默认",
  "Set as default": "设置为默认",
  "Edit": "修改",
  "Remove": "移除",
  "Warning": "提醒",
  "Remove this address from the list?": "确定移除该地址？",
  "Deactive successfully": "禁用成功",
  "Active successfully": "启用成功",
  "Yes": "是",
  "No": "否",
  "Add New Address": "新地址",
  "Address Detail": "地址详情",
  "Set as default address": "设置为默认地址",
  "All Subordinate": "所有",
  "Company Total Balance": "公司应付款余额",
  "Date": "日期",
  "Balance Detail": "应付明细",
  "Please select": "请选择",
  "All Orders": "所有",
  "Successful operation": "操作成功",
  "SO NO": "订单号",
  "Order Date": "下单日期",
  "Delivery Estimate": "预计送货日期",
  "Order Total": "订单总价",
  "Order Status": "订单状态",
  "Order Person": "下单人",
  "View Order Details": "查看明细",
  "Reorder": "再来一单",
  "Order Summary": "订单汇总",
  "Subtotal": "产品总价",
  "Tax": "税金",
  "Promotion": "折扣",
  "Shipping": "配送费",
  "Total": "总金额",
  "Order Detail": "订单明细",
  "Pick Up": "自提",
  "Delivery": "配送",
  "Print Order": "打印订单",
  "Print Invoice": "打印发票",
  "Download QR": "下载我的二维码",
  "Add": "新成员",
  "Subordinate Detail": "团队成员信息",
  "Removed successfully": "移除成功",
  "Please add the product to the shopping cart first": "请先将产品加入购物车",
  "Payment Type": undefined,
  "Payment Information": undefined,
  "Cardholder Name": undefined,
  "Please enter cardholder name": undefined,
  "NAME ON CARD": undefined,
  "Card Number": undefined,
  "Please enter card number": undefined,
  "ENTER CARD NUMBER": undefined,
  "Expiration Date": undefined,
  "Please enter expiration date": undefined,
  "MM/YY": "MM/YY",
  "CVV": "CVV",
  "Please enter cvv": "请输入CVV",
  "ENTER CVV": "CVV",
  "Discount": "优惠",
  "Discount code": "优惠码",
  "Apply": "应用",
  "Delivery Information": "配送信息",
  "Size": "尺寸",
  "Remove item": "删除",
  "Place An order": "下单",
  "Already have account?": "已注册",
  "Shopping Cart": "购物车",
  "Added successfully": "添加成功",
  "Edited successfully": "修改成功",
  "Payment Method": "付款方式",
  "Method": "方式",
  "Get in Touch with Us: Contact Our Friendly Team Today!": "请与我们热心的团队小伙伴联系！",
  "Sent successfully": "发送成功",
  "Message": "留言",
  "Send": "发送",
  "SOCIAL MEDIAS": "社交媒体",
  "Void": "作废",
  "Invoice Total": "发票总价",
  "Invoice Summary": "发票汇总",
  "Deactive": "禁用",
  "Active": "启用",
  "Confirm to deactivate the subordinate?": "确定禁用该账户？",
  "Confirm to activate the subordinate?": "确定启用该账户？",
  "Void successfully": "作废成功",
  "Edit Order": "修改订单",
  "Select More": "选择更多",
  "Please add product first": "请先添加产品",
  "Please enter your phone number": "请输入您的电话号码",
  "Promo code successful. {percent} off your order.": "促销码成功。你的订单打{percent}的折扣。",
  "Free": "免费",
  "The new password is the same as the old password": "新密码和旧密码相同",
  "Egg": "蛋类",
  "Use my credit balance": "积分支付",
  "Select All": "选择全部",
  "Order is packing，not allowed to modify.": "订单已打包，不允许修改。",
  "Price is subject to vendor receipt,and the price on web is refer to the previous day.": "价格以供应商收据为准，网站价格为前一日价格。",
  "Transaction Fee 3%":"交易费3%",
  "Pkg Size":"包装尺寸",
  "Return Total": "退货总价",
  "You can edit order by 6:00am EST next day.": "您可以在美国东部时间第二天上午6:00之前编辑订单。",
  "Account cancellation": "注销账户",
  "Are you sure to cancel the account? Once confirmed, the account data will be cleared within 7 working days": "确定注销账号吗,确定后会在7个工作日内清除账号数据",
  "": undefined,
}
