import {trim, isFunction} from 'lodash'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import html2canvas from 'html2canvas';

import regs from '@/common/regs'
import {
  DATE_FORMAT,
  DEFAULT_LOCATION,
  LOCATION_LIST,
  REDIRECT_KEY,
  TOKEN_KEY,
  USER_ZIPCODE_KEY,
  PICK_UP_KEY,
  DELIVERY_KEY,
  PRODUCT_ITEM_BTN_TYPE_2,
  PRODUCT_ITEM_BTN_TYPE_1,
  STORAGE_LANGUAGE_KEY,
  LANGUAGE_ENGLISH_KEY,
  EDIT_ORDER_PRODUCT_LIST_KEY,
  EDIT_ORDER_IS_GET_ORDER_DETAILS_KEY,
} from '@/constants';

import visaIcon from '@/assets/img/icon/visa.png'
import masterCardIcon from '@/assets/img/icon/masterCard.png'
import americanExpressIcon from '@/assets/img/icon/americanExpress.png'
import wechatIcon from '@/assets/img/icon/wechat.png'
import moment from 'moment';
import UAParser from 'ua-parser-js';

dayjs.extend(utc)

/**
 * 或是是否是手机上显示（手机网页、手机APP）
 * @returns {boolean}
 */
export function getIsMobile () {
  const userAgent = navigator.userAgent
  var parser = new UAParser();
  let device = parser.getDevice();
  return /mobile/i.test(userAgent) || device.type == 'tablet' || device.model == 'iPad';
}

/**
 * 
 * @param classNameList
 * @returns {*}
 */
export function getValidClassNameList (classNameList) {
  return classNameList.map(item => trim(item, ' ')).filter(item => item)
}
/**
 * 获取className
 * @param defaultClassNameList
 * @param addClassNameList
 * @param isAdd
 * @returns {string}
 */
export function getUsingClassName (defaultClassNameList = [], addClassNameList = [], isAdd = true) {
  const validDefaultClassNameList = getValidClassNameList(defaultClassNameList);
  const validAddClassNameList = getValidClassNameList(addClassNameList);
  const usingClassNameList = isAdd ? validDefaultClassNameList.concat(validAddClassNameList) : validDefaultClassNameList
  return usingClassNameList.join(' ')
}

export function checkViewableLanguages(data, lang) {
  for (let i = 0; i < data.split(',').length; i++) {
    let d = data.split(',')[i];
    if (d.toLowerCase()==='zh') { d = 'ch'}
    if (d.toLowerCase() === lang) {
      return true;
    }
  }
  return false;
}

export function setToken(token) {
  localStorage.setItem(TOKEN_KEY, token || '')
}
export function removeToken() {
  localStorage.removeItem(TOKEN_KEY)
}
export function getToken() {
  return localStorage.getItem(TOKEN_KEY) || ''
}

export function setUserZipcode(zipcode) {
  localStorage.setItem(USER_ZIPCODE_KEY, zipcode || '')
}
export function removeUserZipcode() {
  return localStorage.removeItem(USER_ZIPCODE_KEY)
}
export function getUserZipcode() {
  return localStorage.getItem(USER_ZIPCODE_KEY)
}
export function getLocationInfo() {
  const userZipcode = getUserZipcode()
  const resItem = LOCATION_LIST.find(item => item.zipcode === userZipcode) || DEFAULT_LOCATION
  return {
    Company_id_id: resItem.companyId,
    warehouse_id: resItem.pickupWarehouseIdId
  }
}

export function getEncodeStr(str) {
  return encodeURIComponent(str)
}
export function getDecodeStr(str) {
  return decodeURIComponent(str)
}

export function checkLogin(navigate, isLogged, location, callback) {
  if (!isLogged) {
    const searchParams = new URLSearchParams({[REDIRECT_KEY]: getEncodeStr(location.pathname)})
    return navigate(`/signupLogin?${searchParams}`)
  }
  isFunction(callback) && callback()
}

export function getFormatMoney(value) {
  if (!value && value !== 0) {
    return ''
  }
  return value
  // const [num1, num2] = Number(value).toFixed(2).split('.')
  // return `${Number(num1).toLocaleString()}.${num2}`
}

export function getDayjs() {
  return dayjs
}

export function getIsValidByDayjsObj (dateString) {
  return getDayjs()(dateString).isValid()
}

export function getDayjsDate(date, format = DATE_FORMAT, ...other) {
  const dayjs = getDayjs()
  return (date && getIsValidByDayjsObj(date)) ? dayjs(date, format, ...other) : date
}

export function getFormatDate(date, format = DATE_FORMAT, ...other) {
  const dayjs = getDayjs()
  return (date && getIsValidByDayjsObj(date)) ? dayjs(date).format(format) : date
}


export function getPaymentTypeList() {
  return [
    {
      label: visaIcon,
      value: 'creditcard',
    },
    {
      label: masterCardIcon,
      value: '2',
    },
    {
      label: americanExpressIcon,
      value: '3',
    },
    {
      label: wechatIcon,
      value: '4',
    },
  ]
}

export function getShoppingMethodList() {
  return [
    {
      label: 'Pick Up',
      value: PICK_UP_KEY,
    },
    {
      label: 'Delivery',
      value: DELIVERY_KEY,
    }
  ]
}

export function getFormatProductListByCartList(originData, cartList) {
  return originData.map(item => {
    const resIte = cartList.find(ite => ite.Product_id === item.Product_id)
    return {
      ...item,
      Quantity: resIte?.Quantity || 0
    }
  })
}

export function getProductItemBtnType(qty) {
  return qty > 0 ? PRODUCT_ITEM_BTN_TYPE_2 : PRODUCT_ITEM_BTN_TYPE_1
}

export function requestFullScreen(ele) {
  if (ele.requestFullscreen) {
    ele.requestFullscreen();
  } else if (ele.mozRequestFullScreen) {
    ele.mozRequestFullScreen();
  } else if (ele.webkitRequestFullscreen) {
    ele.webkitRequestFullscreen();
  } else if (ele.msRequestFullscreen) {
    ele.msRequestFullscreen();
  }
}

export function exitFullscreen() {
  if (document.exitFullScreen) {
    document.exitFullScreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();// IE 退出全屏
  }
}

// 判断当前是否全屏
export function isFullScreen() {
  return !!(document.fullscreenElement ||
    document.mozFullScreen ||
    document.webkitIsFullScreen ||
    document.webkitFullScreen ||
    document.msFullScreen ||
    document.msFullscreenElement // IE浏览器
  );
}

export function downloadFile(file, filename, isUrl) {
  let filePath = file
  if (!isUrl) {
    filePath = window.URL.createObjectURL(file)
  }
  const domElement = document.createElement('a')
  domElement.href = filePath
  domElement.download = filename
  domElement.click()
  URL.revokeObjectURL(filePath)
}

export  function getLanguage() {
  return localStorage.getItem(STORAGE_LANGUAGE_KEY) || LANGUAGE_ENGLISH_KEY
}

export function setLanguage(language = LANGUAGE_ENGLISH_KEY) {
  localStorage.setItem(STORAGE_LANGUAGE_KEY, language)
}

export function getBase64Img(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

/**
 * 压缩图片
 * @param file
 */
export function compressImg(file) {
  return new Promise(resolve => {
    const fileSize = file.size
    const m1 = 1048576 // 1048576B = 1024KB = 1M
    // 小于1M直接返回
    if (fileSize < m1) {
      return resolve(file)
    }
    const fileUrl = file.url
    const fileType = file.type
    const fileName = file.name
    // 压缩率，压缩到m1的大小左右
    const cRatio = Number((m1 / fileSize).toFixed(2))
    let img = new Image()
    img.src = fileUrl
    // 图片加载完成后再用canvas画图
    img.onload = () => {
      const canvasWidth = img.naturalWidth //图片原始长宽
      const canvasHeight = img.naturalHeight
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d')
      canvas.width = canvasWidth
      canvas.height = canvasHeight
      ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight)
      canvas.toBlob(blob => {
        const resFile = new File([blob], fileName, {
          type: fileType,
          lastModified: Date.now()
        })
        resolve(resFile)
      }, fileType, cRatio)
    }
  })
}

/**
 * 打印指定元素内容
 * @param element
 * @param callback
 */
export function printElement(element, callback) {
  const iframe = document.createElement('iframe')
  iframe.style.display = 'none'
  document.body.append(iframe)
  const iframeWindow = iframe.contentWindow || iframe
  html2canvas(element, {allowTaint: true}).then(function(canvas) {
    // 多页的时候，第一页空白的问题
    iframeWindow.document.body.style.marginTop = '0px';
    iframeWindow.document.body.append(canvas)
    iframe.focus()
    iframeWindow.print()
    iframe.remove()
    isFunction(callback) && callback()
  });
}

export function verifyUSAPhoneNum(phone) {
  let dealPhone = phone;
  if(phone.indexOf('-') != -1) {
    dealPhone = phone.replaceAll('-', '');
  }
  return regs.USAPhoneNum3.test(dealPhone)
}

export function formRulesPhoneNumValidator(_, value, errMsg) {
  return (!value || verifyUSAPhoneNum(value)) ?
    Promise.resolve() :
    Promise.reject(new Error(errMsg))
}

export function getEditOrderProductList() {
  const productList = localStorage.getItem(EDIT_ORDER_PRODUCT_LIST_KEY)
  return productList ? JSON.parse(productList) : []
}
export function setEditOrderProductList(productList = []) {
  localStorage.setItem(EDIT_ORDER_PRODUCT_LIST_KEY, JSON.stringify(productList))
}
export function getEditOrderIsGetOrderDetails() {
  const editOrderIsGetOrderDetails = localStorage.getItem(EDIT_ORDER_IS_GET_ORDER_DETAILS_KEY)
  return editOrderIsGetOrderDetails ? JSON.parse(editOrderIsGetOrderDetails) : false
}
export function setEditOrderIsGetOrderDetails(editOrderIsGetOrderDetails = false) {
  localStorage.setItem(EDIT_ORDER_IS_GET_ORDER_DETAILS_KEY, JSON.stringify(editOrderIsGetOrderDetails))
}

export const isOver8FromEast = (createTime) => {
  if(!createTime || createTime.indexOf('T') == -1) {
    return true;
  }
  // 获取年月日
  let yMd = createTime.split('T');
  // 获取月日
  if(yMd[0].indexOf('-') == -1) {
    return true;
  }
  let ymds = yMd[0].split('-');
  let createYear = ymds[0];
  let createMonth = ymds[1];
  let createDay= ymds[2];
  let createHour= yMd[1].substring(0,2);
  let current = moment().utc().format('YYYY-MM-DD');
  let currents = current.split('-');
  let currentYear = currents[0];
  let currentMonth = currents[1];
  let currentDay = currents[2];
  let currentHour = moment().utc().hour();
  // 为true 不能修改
  // 同一天，可以修改 4/12 utc 时间比 东部时间早4小时
  if(createYear == currentYear && createMonth == currentMonth && createDay == currentDay && (parseInt(createHour) >= 4 || parseInt(createHour) < 4 && currentHour < 10)) {
    return false;
  }
  if(createYear == currentYear && createMonth == currentMonth && currentDay - createDay == 1 && parseInt(createHour) < 4) {
    return true;
  }
  // 年月不同 true  日期 当前-创建 != 1
  return (createYear != currentYear || createMonth != currentMonth ||
    (currentDay- createDay != 1) || (currentDay - createDay == 1 && currentHour >= 10));  
}
